import { useSelector } from 'react-redux'
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'
import { amber, green, grey, orange, red } from '@mui/material/colors'
import Font from './components/font'

export const config = (themeColor, themeFont) => {
  let palette = {
    primary: { main: themeColor || grey[700] },
    secondary: { main: themeColor || grey[700] },
    background: { default: grey[100] },
    available: {
      contrastText: '#000',
      dark: green[400],
      light: green[200],
      main: green[300],
    },
    reserved: {
      contrastText: '#000',
      dark: amber[400],
      light: amber[200],
      main: amber[300],
    },
    booked: {
      contrastText: '#000',
      dark: orange[400],
      light: orange[200],
      main: orange[300],
    },
    sold: {
      contrastText: '#000',
      dark: red[400],
      light: red[200],
      main: red[300],
    },
    leased: {
      contrastText: '#000',
      dark: grey[400],
      light: grey[200],
      main: grey[300],
    },
    upcoming: {
      contrastText: '#000',
      dark: grey[400],
      light: grey[200],
      main: grey[300],
    },
  }

  const { name } = themeFont || {}
  const fontFamily = [name, 'Roboto', 'sans-serif'].filter((i) => !!i).join(',')

  return createTheme({
    palette,
    typography: {
      useNextVariants: true,
      body2: {
        fontSize: 16,
        lineHeight: 1.5,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            WebkitFontSmoothing: 'auto',
          },
          h1: { fontFamily },
          h2: { fontFamily },
          h3: { fontFamily },
          h4: { fontFamily },
          h5: { fontFamily },
          h6: { fontFamily },
        },
      },
    },
  })
}

export const Theme = ({ children }) => {
  const themeColor = useSelector((state) => state?.company?.themeColor)
  const themeFont = useSelector((state) => state?.company?.themeFont)
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={config(themeColor, themeFont)}>
        <Font font={themeFont} />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default Theme
