import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box, Snackbar } from '@mui/material'
import SignupForm from './signupForm'
import ConfirmationDialog from './confirmationDialog'
import PrivacyPolicyDialog from './privacyPolicyDialog'
import { getToken, getPropertyId } from './utils'

const FormWidget = () => {
  const { t } = useTranslation()
  const { projectId } = useParams()

  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const properties = useSelector((state) => state?.properties)
  const project = useSelector((state) => state?.project)
  const htmls = useSelector((state) => state?.htmls)

  useEffect(() => {
    if (project?.name) {
      console.log(`Rebla form: Found project ${project.name} (${project.id})`)
    }
  }, [project])

  useEffect(() => {
    const propertyId = getPropertyId(document)
    const p = properties?.find?.((p) => p.id == propertyId)
    if (p?.id) {
      console.log(`Rebla form: Found property ${p?.number} (${p?.id})`)
    }
  }, [properties])

  const handleSuccessSignup = () => {
    const redirectUrl = project?.widgetRedirectUrl ?? ''
    redirectUrl ? window.location.href = redirectUrl : setOpenConfirmation(true)
  }

  const handleErrorSignup = () => {
    setOpenSnackbar(true)
  }

  const handleOpenPrivacyPolicy = () => {
    setOpenPrivacyPolicy(true)
  }

  const handleClosePrivacyPolicy = () => {
    setOpenPrivacyPolicy(false)
  }

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false)
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const token = getToken(document)
  const propertyId = getPropertyId(document)
  const privacyPolicyText = htmls?.find?.(
    (h) => h?.key === 'privacy-policy'
  )?.value

  return (
    <Box sx={{ p: 1, backgroundColor: '#fff' }}>
      <SignupForm
        projectId={projectId}
        propertyId={propertyId}
        onOpenPrivacyPolicy={handleOpenPrivacyPolicy}
        onSuccess={handleSuccessSignup}
        onError={handleErrorSignup}
        token={token}
      />
      <PrivacyPolicyDialog
        content={privacyPolicyText}
        open={openPrivacyPolicy}
        onClose={handleClosePrivacyPolicy}
      />
      <ConfirmationDialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
      />
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={t('Something went wrong')}
      />
    </Box>
  )
}

export default FormWidget
